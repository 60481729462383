/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.color-box-legend {
  display: flex;
  justify-content: center;
}

.color-box-legend > *:not(:first-child) {
  margin-left: 24px;
}

.color-box-legend .color-box-legend-rect {
  display: inline-block;
  margin-top: 1px;
  margin-right: 4px;
  border: 1px solid;
}

.color-box-legend .color-box-legend-rect-inner {
  display: block;
  width: 8px;
  height: 8px;
  opacity: 0.2;
}

.color-box-legend.color-box-full .color-box-legend-rect-inner {
  opacity: 1;
}

.color-box-legend button {
  color: #333;
  border-bottom: none;
  display: block;
}

.color-box-legend button.filtered {
  opacity: 0.3;
}
