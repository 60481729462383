/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
a {
  border-bottom: 1px solid #cae3f2;
  color: #236a97;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: all 0.2s ease;
}

a:hover,
a:active,
a:focus {
  color: #4b9fd5;
}

.link-base-color {
  border-bottom: 1px solid #d0d0d0 !important;
  color: #333 !important;
}

.link-base-color:hover,
.link-base-color:active,
.link-base-color:focus {
  color: #4b9fd5 !important;
}

.link-base-color:hover {
  border-bottom-color: #cae3f2 !important;
}

.link-base-color:active,
.link-base-color:focus {
  border-bottom-color: #cae3f2 !important;
}

.link-no-underline {
  border-bottom: none !important;
}

.link-underline {
  border-bottom: 1px solid #cae3f2 !important;
}

.link-with-icon {
  border-bottom: none;
}

.link-with-icon > span:last-child {
  border-bottom: 1px solid #cae3f2;
}

.link-checkbox {
  color: inherit;
  border-bottom: none;
}

.link-checkbox.disabled,
.link-checkbox.disabled:hover,
.link-checkbox.disabled label {
  color: #666;
  cursor: not-allowed;
}

.link-checkbox:hover,
.link-checkbox:active,
.link-checkbox:focus {
  color: inherit;
}

.link-checkbox-control {
  display: inline-block;
  padding: 4px 0 5px;
  line-height: 16px;
}

a.active-link,
.link-active {
  border-bottom: none;
  cursor: default;
}

a.text-muted {
  border-bottom: 1px solid #dddddd;
  color: #666;
}

a.text-muted:hover,
a.text-muted:active,
a.text-muted:focus {
  color: #5e5e5e;
}

a.text-muted:focus {
  outline: 1px dotted #4b9fd5;
}

a.set-homepage-link:focus,
a.favorite-link:focus {
  outline: 1px dotted #4b9fd5;
}
