/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.snippet {
  margin: 8px;
  border: 1px solid #cdcdcd;
  overflow-x: auto;
  overflow-y: hidden;
  transition: max-height 0.2s;
}

.snippet > div {
  display: table;
  width: 100%;
  position: relative;
  transition: margin-top 0.2s;
}

.snippet table {
  width: 100%;
}

.expand-block {
  position: absolute;
  z-index: 2;
  width: 100%;
}

.expand-block > button {
  background: transparent;
  box-sizing: border-box;
  color: #666;
  height: 20px;
  width: 100%;
  padding: 2px;
  border: 0;
  text-align: left;
  cursor: pointer;
}

.expand-block > button:hover,
.expand-block > button:focus,
.expand-block > button:active {
  color: #236a97;
  outline: none;
}

.expand-block-above {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAADdJREFUCB1dzMEKADAIAlBd1v9/bcc2YgRjHh8qq2qTxCQzsX4wM6y30RARF3sy0Es1SIK7Y64OpCES1W69JS4AAAAASUVORK5CYII=');
  top: 0;
}

.expand-block-below {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4wQQBjQEQVd5jwAAADhJREFUCNddyTEKADEMA8GVA/7/Z+PGwUp1cGTaYe/tv5lxrLWoKj6SiMzkjZDEG7JtANt0N+ccLrB/KZxXTt7fAAAAAElFTkSuQmCC');
  bottom: 0;
}

.source-table.expand-up {
  margin-top: 20px;
}

.source-table.expand-down {
  margin-bottom: 20px;
}
